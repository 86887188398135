import { IconProps } from '@/utilities/types/Icon';

const Hospitality = (props: IconProps) => {
  const { height = 24, width = 24 } = props;

  return (
    <svg
      clipRule="evenodd"
      fill="none"
      fillRule="evenodd"
      height={height}
      viewBox="0 0 23 21"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M2.137 15.5c-.167-3.333 1.399-10 8.994-10s8.994 6.667 8.994 10"
      />
      <rect
        width="20"
        height="4"
        x="1.125"
        y="15.5"
        stroke="currentColor"
        strokeWidth="2"
        rx="1"
      />
      <circle
        cx="11.125"
        cy="3.5"
        r="2"
        stroke="currentColor"
        strokeWidth="2"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeWidth="2"
        d="M12 8.492c2 .5 3.333 1.5 4 3"
      />
    </svg>
  );
};

export default Hospitality;
